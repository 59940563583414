import { gql } from '@apollo/client';

export const GET_CRUISES = gql`
  query Query($pagination: PaginationArg) {
    cruises(pagination: $pagination) {
      data {
        attributes {
          name
        }
        id
      }
      meta {
        pagination {
          page
          pageCount
        }
      }
    }
  }
`;

export const GET_CRUISES_BY_DATE = gql`
  query Query($filters: CruiseDateFiltersInput) {
    cruiseDates(filters: $filters) {
      data {
        attributes {
          cruise_ids {
            data {
              id
              attributes {
                name
              }
            }
          }
          date
          ETA
          ETD
        }
      }
    }
  }
`;

export const ADD_NEW_CRUISE = gql`
  mutation CreateCruise($data: CruiseInput!) {
    createCruise(data: $data) {
      data {
        attributes {
          name
          publishedAt
        }
      }
    }
  }
`;

export const GET_DATES_BY_CRUISE = gql`
  query CruiseDate($filters: CruiseDateFiltersInput, $sort: [String]) {
    cruiseDates(filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          eta
          etd
          date
        }
      }
    }
  }
`;

export const DELETE_CRUISE = gql`
  mutation Mutation($deleteCruiseId: ID!) {
    deleteCruise(id: $deleteCruiseId) {
      data {
        id
      }
    }
  }
`;

export const DELETE_CRUISE_DATE = gql`
  mutation Mutation($deleteCruiseDateId: ID!) {
    deleteCruiseDate(id: $deleteCruiseDateId) {
      data {
        id
      }
    }
  }
`;

export const ADD_CRUISE_DATE = gql`
  mutation Mutation($data: CruiseDateInput!) {
    createCruiseDate(data: $data) {
      data {
        id
      }
    }
  }
`;
