import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  drawerWrapper: {
    [themeBreakpoints.down('sm')]: {
      marginTop: '90px',
      width: '230px',
    },
  },
  drawerContent: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 100px)',
    justifyContent: 'space-between',
    overflowX: 'hidden',
    [themeBreakpoints.down('sm')]: {
      height: 'calc(100vh - 90px)',
    },
  },
  drawerList: {
    width: '90%',
  },
  drawerButtonIconBox: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: '10px',
    color: COLORS.gray,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#F6F6F9',
    },
  },
  drawerItemActive: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    padding: '10px',
    backgroundColor: COLORS.lightGreen,
    borderRadius: '10px',
    color: COLORS.green,
    textDecoration: 'none',
  },
  drawerProfile: {
    width: '100%',
  },
  drawerProfileContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  drawerItemProfile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    marginBottom: '10px',
    marginTop: '10px',
  },
  dashBoardItemFont: {
    fontSize: 21,
    fontWeight: 600,
    color: '#151B25',
    [themeBreakpoints.down('md')]: {
      fontSize: 15,
    },
    fontFamily: ['Poppins'].join(','),
  },
  avatar: {
    fontSize: 17,
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    padding: '5px',
    backgroundColor: COLORS.orange,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.white,
    fontWeight: 500,
    fontFamily: ['Poppins'].join(','),
  },
  divider: {
    border: '1px solid #E8E8EE',
    width: '100%',
  },
}));

export default styles;
