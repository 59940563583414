import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken_] = useState(localStorage.getItem('cad-jwt-token'));
  const [userId, setUserId_] = useState(localStorage.getItem('cad-user-id'));

  // Function to set the authentication token
  const setToken = (newToken) => {
    setToken_(newToken);
  };

  // Function to set the authentication token
  const clearToken = () => {
    setToken_(undefined);
  };

  const setUserId = (newId) => {
    setUserId_(newId);
  };

  const clearUserId = () => {
    setUserId_(undefined);
  };

  useEffect(() => {
    if (token) {
      // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem('cad-jwt-token', token);
    } else {
      // delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem('cad-jwt-token');
    }
  }, [token]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem('cad-user-id', userId);
    } else {
      localStorage.removeItem('cad-user-id');
    }
  }, [userId]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      clearToken,
      userId,
      setUserId,
      clearUserId,
    }),
    [token, userId],
  );

  // Provide the authentication context to the children components
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

// Custom hook to easily access the authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
