// Queries to test the JWT token....... DELETE WHEN THE BACKOFFICE QUERIES ARE READY
import { gql } from '@apollo/client';

export const GET_TOURS = gql`
  query Data($sort: [String]) {
    tours(sort: $sort) {
      data {
        id
        attributes {
          name
          overview
          price
          min_capacity
          location
          languages
          itineray
          is_ticket
          included
          images {
            data {
              attributes {
                url
              }
            }
          }
          duration
          tag
        }
      }
      meta {
        pagination {
          pageCount
        }
      }
    }
  }
`;
