import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';

const styles = makeStyles((theme) => ({
  tagPHAP: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    textDecoration: 'none',
    backgroundColor: COLORS.yellowTag,
    borderRadius: '50px',
    gap: 10,
    fontFamily: ['Poppins'].join(','),
    fontSize: 12,
    width: 'fit-content',
  },
  tagCP: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    textDecoration: 'none',
    backgroundColor: COLORS.grayTag,
    borderRadius: '50px',
    gap: 10,
    fontFamily: ['Poppins'].join(','),
    fontSize: 12,
    width: 'fit-content',
  },
  tagCERN: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    textDecoration: 'none',
    backgroundColor: COLORS.redTag,
    color: COLORS.white,
    borderRadius: '50px',
    gap: 10,
    fontFamily: ['Poppins'].join(','),
    fontSize: 12,
    width: 'fit-content',
  },
  tagCR: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    textDecoration: 'none',
    backgroundColor: COLORS.greenTag,
    borderRadius: '50px',
    gap: 10,
    color: COLORS.white,
    fontFamily: ['Poppins'].join(','),
    fontSize: 12,
    width: 'fit-content',
  },
}));

export default styles;
