import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        role {
          description
        }
      }
    }
  }
`;

export const GET_USER_NAME = gql`
  query Attributes($usersPermissionsUserId: ID) {
    usersPermissionsUser(id: $usersPermissionsUserId) {
      data {
        attributes {
          name
        }
      }
    }
  }
`;
