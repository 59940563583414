import React from 'react';

import styles from './styles';

const AddTourPage = () => {
  const classes = styles();

  return (
    <div className={classes.mainWrapper}>
      <h1>Add tour</h1>
    </div>
  );
};

export default AddTourPage;
