import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import breakpoints from './breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const typography = {
  h1: {
    fontSize: 40,
    fontWeight: 700,
    color: '#151B25',
    [themeBreakpoints.down('md')]: {
      fontSize: 20,
    },
    fontFamily: ['Poppins'].join(','),
  },
  h2: {
    fontSize: 32,
    fontWeight: 700,
    color: '#151B25',
    [themeBreakpoints.down('md')]: {
      fontSize: 18,
    },
    fontFamily: ['Poppins'].join(','),
  },
  h3: {
    fontSize: 26,
    fontWeight: 600,
    color: '#151B25',
    [themeBreakpoints.down('md')]: {
      fontSize: 15,
    },
    fontFamily: ['Poppins'].join(','),
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    color: '#151B25',
    [themeBreakpoints.down('md')]: {
      fontSize: 15,
    },
    fontFamily: ['Poppins'].join(','),
  },
  h5: {
    fontSize: 21,
    fontWeight: 600,
    color: '#151B25',
    [themeBreakpoints.down('md')]: {
      fontSize: 15,
    },
    fontFamily: ['Poppins'].join(','),
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    color: '#151B25',
    fontFamily: ['Poppins'].join(','),
  },
};

export default typography;
