import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { COLORS } from '../constants';
import breakpoints from './breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.gray,
          borderRadius: '10px',
          borderWidth: '1px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.green,
        },
      },
      input: {
        padding: '15.5px 14px',
        borderRadius: `15px`,
        '&.MuiInputBase-inputSizeSmall': {
          padding: '10px 14px',
          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: 0,
          },
        },
      },
      inputAdornedStart: {
        paddingLeft: 4,
      },
      notchedOutline: {
        borderRadius: `15px`,
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        borderWidth: 1,
        backgroundColor: COLORS.white,
        '&.Mui-selected': {
          backgroundColor: `${COLORS.green} !important`,
          color: `${COLORS.white} !important`,
        },
      },
      today: {
        color: COLORS.gray,
        backgroundColor: `${COLORS.white} !important`,
        borderColor: `${COLORS.green} !important`,
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: {
        borderWidth: 1,
        backgroundColor: COLORS.white,
        '&.Mui-selected': {
          backgroundColor: `${COLORS.green} !important`,
          color: `${COLORS.white} !important`,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardInfo: {
        backgroundColor: `${COLORS.lightGreen} !important`,
        color: `${COLORS.gray} !important`,
        '& .MuiAlert-icon	': {
          color: `${COLORS.black} !important`,
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${COLORS.green} !important`,
          color: `${COLORS.white} !important`,
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '10px',
        [themeBreakpoints.down('sm')]: {
          borderRadius: '0px',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: `${COLORS.blue} !important`,
        color: `${COLORS.white} !important`,
        fontFamily: ['Poppins'].join(','),
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: `${COLORS.lightLime} !important`,
        '&.MuiStepIcon-root': {
          width: '35px',
          height: '35px',
          [themeBreakpoints.down('sm')]: {
            width: '25px',
            height: '25px',
          },
        },
        '.MuiStepIcon-text': {
          fill: COLORS.black,
          fontWeight: 600,
        },
        '&.Mui-active': {
          color: `${COLORS.green} !important`,
        },
        '&.Mui-active .MuiStepIcon-text': {
          fill: COLORS.white,
        },
        '&.Mui-completed': {
          color: `${COLORS.orange} !important`,
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        '.MuiStepConnector-lineVertical': { borderColor: COLORS.white },
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        '.MuiStepLabel-label': {
          fontWeight: 600,
          fontSize: '24px',
          [themeBreakpoints.down('sm')]: {
            fontSize: '18px',
          },
          '&.Mui-active': {
            color: `${COLORS.black} !important`,
            fontWeight: 600,
          },
          '&.Mui-completed': {
            color: `${COLORS.black} !important`,
            fontWeight: 600,
          },
        },
      },
    },
  },
  MuiStepContent: {
    styleOverrides: {
      root: {
        borderColor: COLORS.white,
        paddingLeft: '0px',
        marginLeft: '0px',
        marginTop: '10px',
        [themeBreakpoints.down('sm')]: {
          paddingLeft: '0px',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '.MuiFormControlLabel-label': {
          fontSize: '16px',
        },
      },
    },
  },
  MuiPickersSlideTransition: {
    styleOverrides: {
      root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '0 50px 10px 0',
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        borderRadius: '10px',
        gap: 10,
        height: 'auto',
        [themeBreakpoints.down('sm')]: {
          gap: 0,
          width: '100%',
        },
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '5px',
        height: '50px',
        color: '#151B25',
        '&.Mui-selected': {
          backgroundColor: `#ECF6E7 !important`,
          color: COLORS.green,
        },
        [themeBreakpoints.down('sm')]: {
          padding: '0px',
          minWidth: '50px',
        },
      },
      label: {
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: ['Poppins'].join(','),
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: { margin: 0 },
    },
  },
  MuiPickersPopper: {
    styleOverrides: {
      root: { zIndex: 10000 },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: { zIndex: 10000 },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        '&::-webkit-scrollbar': {
          height: '3px',
          width: '3px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '5px',
          backgroundColor: '#FFFFFF',
        },
        '&::-webkit-scrollbar-track:hover': {
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-track:active': {
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '5px',
          backgroundColor: COLORS.scrollbar,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: COLORS.scrollbar,
        },
        '&::-webkit-scrollbar-thumb:active': {
          backgroundColor: COLORS.scrollbar,
        },
      },
    },
  },
  MuiMultiSectionDigitalClockSection: {
    styleOverrides: {
      root: {
        width: '55px',
        '& .Mui-selected': {
          // backgroundColor: COLORS.lightGreen,
          color: COLORS.white, // Change text color when selected
        },
      },
    },
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      root: {
        top: '0px !important',
        '& .MuiPickersArrowSwitcher-spacer': {
          width: '0px',
        },
      },
    },
  },
  MuiClockNumber: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          // backgroundColor: COLORS.lightGreen,
          color: COLORS.white, // Change text color when selected
        },
      },
    },
  },
};

export default components;
