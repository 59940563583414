import { useLazyQuery, useQuery } from '@apollo/client';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import {
  Alert,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Pagination,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  SwipeableDrawer,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QrReader } from 'react-qr-reader';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import BookingCard from '../../components/BookingCard';
import CustomPrimaryButton from '../../components/CustomButton';
import MobileFilterSelector from '../../components/MobileFIlterSelector';
import TabSelector from '../../components/TabSelector/TabSelector';
import { COLORS, PATHS } from '../../constants';
import { formatBooking, formatOneBooking } from '../../helpers/interfaces/bookingInterface';
import { GET_BOOKING_BY_CODE, GET_BOOKINGS } from '../../queries/bookings';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import styles from './styles';

dayjs.extend(utc);
dayjs.extend(timezone);

const bookingPage = () => {
  const navigate = useNavigate();
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const [qrBookingModal, setQrBookingModal] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });

  const ScanBookingModal = ({ modalOpenValue, handleChangeModal }) => {
    const handleCloseModal = () => {
      handleChangeModal(false);
    };

    const ModalContent = () => {
      const [readCode, setReadCode] = useState('');
      const [getBooking, { data: bookingData, loading }] = useLazyQuery(GET_BOOKING_BY_CODE);

      useEffect(() => {
        if (bookingData?.bookings.meta.pagination.pageCount > 0) {
          navigate(PATHS.bookingDetails, { state: { booking: formatOneBooking(bookingData, t) } });
        }
      }, [bookingData]);

      const handleSearchBooking = () => {
        getBooking({ variables: { filters: { code: { eq: readCode } } } });
      };

      return (
        <div className={classes.scanModalContent}>
          <div className={classes.closeButton}>
            <IconButton size='large' onClick={handleCloseModal}>
              <CloseIcon sx={{ color: COLORS.gray, fontSize: '28px' }} />
            </IconButton>
          </div>
          <p className={fontClasses.h3}>{t('bookingPage.scanQR')}</p>
          <QrReader
            constraints={{
              facingMode: 'environment',
            }}
            containerStyle={{ width: '75%', heigth: '50%', backgroundColor: '#E7E7E7' }}
            onResult={(result, error) => {
              if (result) {
                setReadCode(result?.text);
                getBooking({ variables: { filters: { code: { eq: result?.text } } } });
              }
              if (error) {
                console.info(error);
              }
            }}
          />
          {bookingData?.bookings.meta.pagination.pageCount === 0 && (
            <div style={{ width: '100%' }}>
              <Alert severity='error'> {t('bookingPage.invalidCode')}</Alert>
            </div>
          )}
          {loading && (
            <div style={{ dis: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color='primary' />
            </div>
          )}
          <div className={classes.textFieldBox}>
            <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
              {t('bookingPage.reservatationCode')}
            </p>
            <TextField
              id='bookingCode'
              value={readCode}
              onChange={(event) => {
                setReadCode(event.target.value);
              }}
              variant='outlined'
              fullWidth
            />
          </div>
          <CustomPrimaryButton
            label={t('bookingPage.findBooking')}
            fullWidth={isMobile}
            onClick={handleSearchBooking}
            disabled={loading}
          />
        </div>
      );
    };

    return (
      <>
        {!isMobile ? (
          <Dialog
            open={modalOpenValue}
            sx={{ zIndex: 6000 }}
            maxWidth='md'
            onClose={handleCloseModal}
            // TransitionComponent={Transition}
          >
            <ModalContent />
          </Dialog>
        ) : (
          <SwipeableDrawer
            anchor={'bottom'}
            open={modalOpenValue}
            onClose={handleCloseModal}
            PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
            sx={{ zIndex: 6000 }}
          >
            <ModalContent />
          </SwipeableDrawer>
        )}
        ;
      </>
    );
  };

  const BookingSection = () => {
    const [filterStatus, setFilterStatus] = React.useState('today');
    const [filterValue, setFilterValue] = useState(
      `{"booking_date":{"between":["${moment().startOf('day').format('YYYY-MM-DD')}","${moment().startOf('day').format('YYYY-MM-DD')}"]}}`,
    );
    const [dateRange1, setDateRange1] = useState(dayjs());
    const [dateRange2, setDateRange2] = useState(dayjs());
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [bookingForms, setBookingForms] = useState([]);

    const handleChangeFilterStatus = (event, newValue) => {
      setFilterStatus(newValue);
    };

    const handlePageChange = (event, value) => {
      setPageNumber(value);
    };

    const {
      data: bookingFormData,
      loading: loadingBooking,
      error: errorBooking,
    } = useQuery(GET_BOOKINGS, {
      variables: {
        pagination: {
          page: pageNumber,
          pageSize: 20,
        },
        sort: 'createdAt:desc',
        filters: JSON.parse(filterValue),
      },
    });

    useEffect(() => {
      if (bookingFormData) {
        setBookingForms(formatBooking(bookingFormData, t));
        setPageCount(bookingFormData.bookings.meta.pagination.pageCount);
      }
    }, [bookingFormData]);

    const handleRange1Change = (event, value) => {
      setDateRange1(event);
    };

    const handleRange2Change = (event, value) => {
      setDateRange2(event);
    };

    useEffect(() => {
      if (filterStatus) {
        switch (filterStatus) {
          case 'today':
            setFilterValue(
              `{"booking_date":{"between":["${moment().startOf('day').format('YYYY-MM-DD')}","${moment().startOf('day').format('YYYY-MM-DD')}"]}}`,
            );
            break;
          case 'week':
            setFilterValue(
              `{"booking_date":{"between":["${moment().startOf('week').format('YYYY-MM-DD')}","${moment().endOf('week').format('YYYY-MM-DD')}"]}}`,
            );
            break;
          case 'month':
            setFilterValue(
              `{"booking_date":{"between":["${moment().startOf('month').format('YYYY-MM-DD')}","${moment().endOf('month').format('YYYY-MM-DD')}"]}}`,
            );
            break;
          case 'all':
            setFilterValue(`{"booking_date":{}}`);
            break;
          case 'custom':
            setFilterValue(
              `{"booking_date":{"between":["${dateRange1.format('YYYY-MM-DD')}","${dateRange2.format('YYYY-MM-DD')}"]}}`,
            );
            break;
        }
      }
    }, [filterStatus, dateRange1, dateRange2]);

    return (
      <div className={classes.section}>
        <div className={classes.mainTitle}>
          <h2 className={fontClasses.h3}>{t('bookingPage.bookings')}</h2>
          {!isMobile && (
            <div className={classes.buttonDiv}>
              <CustomPrimaryButton
                label={t('bookingPage.scan')}
                tertiary
                icon={<QrCodeScannerOutlinedIcon />}
                onClick={() => {
                  setQrBookingModal(true);
                }}
              />
              {/* 
              <CustomPrimaryButton
                label={t('bookingPage.new')}
                tertiary
                icon={<AddOutlinedIcon />}
              />
              */}
            </div>
          )}
          {isMobile && (
            <MobileFilterSelector
              value={filterStatus}
              handleChange={handleChangeFilterStatus}
              dateRange1={dateRange1}
              dateRange2={dateRange2}
              handleRange1Change={handleRange1Change}
              handleRange2Change={handleRange2Change}
            />
          )}
        </div>
        {!isMobile && (
          <div className={classes.filtersBox}>
            <TabSelector
              isMobile={isMobile}
              value={filterStatus}
              handleChange={handleChangeFilterStatus}
            />
            {filterStatus === 'custom' && (
              <div className={classes.customDatesBox}>
                <DesktopDatePicker
                  label={t('bookingPage.from')}
                  orientation='portrait'
                  autoFocus={false}
                  timezone='America/Costa_Rica'
                  className={classes.datePicker}
                  slotProps={{
                    inputAdornment: {
                      position: 'start',
                      id: 'calendarButton',
                    },
                  }}
                  value={dateRange1}
                  onChange={handleRange1Change}
                  sx={{ height: '1px' }}
                />
                <DesktopDatePicker
                  label={t('bookingPage.to')}
                  orientation='portrait'
                  autoFocus={false}
                  timezone='America/Costa_Rica'
                  className={classes.datePicker}
                  slotProps={{
                    inputAdornment: {
                      position: 'start',
                      id: 'calendarButton',
                    },
                  }}
                  value={dateRange2}
                  onChange={handleRange2Change}
                />
              </div>
            )}
          </div>
        )}
        <div className={classes.bookingList}>
          {bookingForms.length > 0 && !loadingBooking && !errorBooking && (
            <>
              {!isMobile && (
                <div className={classes.headerTableBox}>
                  <Grid container sx={{ marginBottom: '20px' }}>
                    <Grid item sm={12} md={2} lg={3} xl={3}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('bookingPage.name')}
                      </p>
                    </Grid>
                    <Grid item sm={12} md={2} lg={2} xl={2}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('bookingPage.code')}
                      </p>
                    </Grid>
                    <Grid item sm={12} md={2} lg={2} xl={2}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('bookingPage.bookingDate')}
                      </p>
                    </Grid>
                    <Grid item sm={12} md={2} lg={1} xl={1}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('bookingPage.quantity')}
                      </p>
                    </Grid>
                    <Grid item sm={12} md={2} lg={2} xl={2}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('bookingPage.total')}
                      </p>
                    </Grid>
                    <Grid item sm={12} md={2} lg={2} xl={2}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('bookingPage.bookingStatus')}
                      </p>
                    </Grid>
                  </Grid>
                </div>
              )}
              {bookingForms.map((item, index) => {
                return <BookingCard key={'cardForm-' + index} bookingData={item} />;
              })}
              {pageCount > 1 && isMobile && (
                <div className={classes.paginationStyle}>
                  <Pagination
                    count={pageCount}
                    color='primary'
                    page={pageNumber}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </>
          )}
          {errorBooking && (
            <div className={classes.emptyList}>
              <div className={classes.emptyMessageBox}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: '50px', color: COLORS.gray }} />
                <h2 className={fontClasses.h2}>{t('validationsForm.errorTitle')}</h2>
                <p className={fontClasses.p}>{t('validationsForm.errorMessage')}</p>
              </div>
            </div>
          )}
          {!loadingBooking && !errorBooking && bookingForms.length === 0 && (
            <div className={classes.emptyList}>
              <div className={classes.emptyMessageBox}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: '50px', color: COLORS.gray }} />
                <p className={fontClasses.p}>{t('bookingPage.emptyMessage')}</p>
              </div>
            </div>
          )}
          {loadingBooking && (
            <div className={classes.loadingBox}>
              {[...Array(4)].map((__, index) => (
                <Skeleton
                  key={'skeleton-booking-' + index}
                  variant='rectangular'
                  width='100%'
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '50px',
                  }}
                />
              ))}
            </div>
          )}
        </div>
        {pageCount > 1 && !isMobile && (
          <div className={classes.paginationStyle}>
            <Pagination
              count={pageCount}
              color='primary'
              page={pageNumber}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.mainWrapper}>
      <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={10}>
          <BookingSection />
        </Grid>
      </Grid>
      {qrBookingModal && (
        <ScanBookingModal modalOpenValue={qrBookingModal} handleChangeModal={setQrBookingModal} />
      )}
      {isMobile && (
        <SpeedDial
          ariaLabel='SpeedDial bookings'
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
          icon={<SpeedDialIcon sx={{ color: COLORS.white }} />}
          FabProps={{
            sx: {
              bgcolor: COLORS.orange,
              '&:hover': {
                bgcolor: COLORS.orange,
              },
            },
          }}
        >
          <SpeedDialAction
            icon={<QrCodeScannerOutlinedIcon sx={{ color: COLORS.orange }} />}
            tooltipTitle='Scan booking'
            onClick={() => {
              setQrBookingModal(true);
            }}
          />
          {/* 
          <SpeedDialAction
            icon={<AddOutlinedIcon sx={{ color: COLORS.orange }} />}
            tooltipTitle='New booking'
          />
          */}
        </SpeedDial>
      )}
    </div>
  );
};

export default bookingPage;
