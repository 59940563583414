import { gql } from '@apollo/client';

const RESET_PASSWORD_FORM = gql`
  mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      jwt
    }
  }
`;

export default RESET_PASSWORD_FORM;
