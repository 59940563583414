export const login = '/';
export const recovery = '/forgot-password';
export const resetPassword = '/reset-password';
export const bookings = '/bookings';
export const bookingDetails = '/booking-details';
export const tours = '/tours';
export const addTour = '/new-tour';
export const toursDetails = '/tours-details';
export const contactForm = '/contact-form';
export const cruise = '/cruises';
export const configuration = '/configuration';
