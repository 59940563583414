import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  tableElement: {
    borderBottom: '1px solid #E8E8EE',
    '&:not(:last-of-type)': {
      borderBottom: '1px solid #E8E8EE', // Borde para todos menos el último
    },
    '&:last-of-type': {
      borderBottom: 'none', // Eliminar el borde del último elemento
    },
  },
  statusBox: {
    width: 'auto',
    display: 'flex',
    [themeBreakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
  accordionSummary: {
    width: '100%',
    padding: '0px !important',
    '&:hover': {
      backgroundColor: COLORS.lightGreen,
    },
    [themeBreakpoints.down('sm')]: {
      '&:hover': {
        backgroundColor: COLORS.white,
      },
    },
  },
  actionButtonsBox: {
    marginTop: '10px',
    display: 'flex',
    gap: 20,
  },
  icon: {
    color: COLORS.black,
    marginTop: '5px',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '25px',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    [themeBreakpoints.down('sm')]: {
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
      width: 'auto',
      padding: '25px',
    },
  },
  textFieldBox: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    [themeBreakpoints.down('sm')]: {
      right: 10,
      top: 15,
    },
  },
}));

export default styles;
