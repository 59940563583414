import React from 'react';

import styles from './styles';

const Tag = ({ type }) => {
  const classes = styles();
  let typeCSS = '';
  if (
    type === 'Awaiting Payment' ||
    type === 'In Progress' ||
    type === 'on Hold' ||
    type === 'Partially Fulfilled'
  ) {
    typeCSS = classes.tagPHAP;
  } else if (
    type === 'Cancelled' ||
    type === 'Expired' ||
    type === 'Rejected' ||
    type === 'No Show'
  ) {
    typeCSS = classes.tagCERN;
  } else if (type === 'Confirmed' || type === 'Pending') {
    typeCSS = classes.tagCP;
  } else if (type === 'Completed' || type === 'Refunded') {
    typeCSS = classes.tagCR;
  }

  return <div className={typeCSS}>{type}</div>;
};

export default Tag;
