export const blue = '#0077FF';
export const black = '#151B25';
export const white = '#ffffff';
export const shadow = '#f5f5f5';
export const gray = '#424242';
export const green = '#65b741';
export const orange = '#FF6C22';
export const lightYellow = '#FAFBE7';
export const lightGreen = '#ECF6E7';
export const red = '#EF5350';
export const lightGray = '#F2F3F7';
export const stroke = '#D5D4DF';
export const yellow = '#FFD93E';
export const lightLime = '#ECF6E7';
export const scrollbar = '#bdbdbd';
export const grayBackground = '#F4F4F4';

// tag
export const yellowTag = '#FFF59D';
export const grayTag = '#EEEEEE';
export const redTag = '#FF6B7A';
export const greenTag = '#AED581';
