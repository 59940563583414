import { useMutation } from '@apollo/client';
import { Alert, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import CustomPrimaryButton from '../../components/CustomButton';
import { COLORS, URL_IMAGES } from '../../constants';
import RESET_PASSWORD_FORM from '../../queries/resetPassword';
import fontStyles from '../../styles/fontStyles';
import styles from './styles';

const ResetPassword = () => {
  const classes = styles();
  const fontClasses = fontStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const useQueryParams = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQueryParams();
  const codeParam = query.get('code');

  const [resetPasswordForm, { loading, error }] = useMutation(RESET_PASSWORD_FORM);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, t('resetPasswordPage.messagePasswordComply'))
      .matches(/[A-Z]/, t('resetPasswordPage.messagePasswordComply'))
      .matches(/[a-z]/, t('resetPasswordPage.messagePasswordComply'))
      .matches(/\d/, t('resetPasswordPage.messagePasswordComply'))
      .required(t('validationsForm.complete')),
    passwordConfirmation: yup
      .string()
      .min(8, t('resetPasswordPage.messagePasswordComply'))
      .matches(/[A-Z]/, t('resetPasswordPage.messagePasswordComply'))
      .matches(/[a-z]/, t('resetPasswordPage.messagePasswordComply'))
      .matches(/\d/, t('resetPasswordPage.messagePasswordComply'))
      .oneOf([yup.ref('password'), null], t('resetPasswordPage.messagePasswordDontMatch'))
      .required(t('validationsForm.complete')),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await resetPasswordForm({
        variables: { ...values, code: codeParam },
      });
      setAlertType('success');
      setAlertMessage(t('resetPasswordPage.messageSuccess'));
      setShowAlert(true);
      formik.resetForm();
    },
  });

  const handleMessage = (event) => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (error) {
      setAlertType('error');
      setAlertMessage(t('validationsForm.serverError'));
      setShowAlert(true);
    }
  }, [error]);

  useEffect(() => {
    if (!codeParam) {
      navigate('/');
    }
  }, [codeParam, navigate]);

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.loginBox}>
        <div className={classes.logoTitle}>
          <Link to='/' className={classes.logoBox}>
            <img
              className={classes.logo}
              src={URL_IMAGES.URL_LOGO_CARRIBEAN_ADVENTURES}
              alt='logo'
            />
          </Link>
          <h5 className={clsx(fontClasses.h5, classes.title)}>{t('resetPasswordPage.title')}</h5>
        </div>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <div className={classes.formBox}>
            <div className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>
                {t('resetPasswordPage.password')}
              </p>
              <TextField
                fullWidth
                variant='outlined'
                id='password'
                name='password'
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <p className={clsx(fontClasses.p, classes.parragraphPassword)}>
                {t('resetPasswordPage.requirementPassword')}
              </p>
            </div>
            <div className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>
                {t('resetPasswordPage.confirmPassword')}
              </p>
              <TextField
                fullWidth
                variant='outlined'
                id='passwordConfirmation'
                name='passwordConfirmation'
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
              />
            </div>
            {Object.keys(formik.errors).length > 0 && formik.submitCount > 0 && (
              <div className={classes.alertError}>
                <Alert severity='error'>
                  {formik.errors.password || formik.errors.passwordConfirmation}
                </Alert>
              </div>
            )}
            {showAlert && (
              <div className={classes.alertError}>
                <Alert onClose={handleMessage} severity={alertType} sx={{ color: COLORS.gray }}>
                  {alertMessage}
                </Alert>
              </div>
            )}
            <div className={classes.buttonBox}>
              {loading && <CircularProgress color='primary' />}
              <CustomPrimaryButton
                fullWidth
                tertiary
                label={t('resetPasswordPage.submitButton')}
                submit
              />
              <Link to='/' className={classes.linkForget} underline='none'>
                <p className={clsx(fontClasses.p, classes.forgetLink)}>
                  {t('resetPasswordPage.loginLink')}
                </p>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
