// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
// import breakpoints from '../../theme/breakpoints';

// const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  mobileFiltersBox: {
    display: 'flex',
    padding: '10px',
    gap: 10,
    alignItems: 'center',
    backgroundColor: COLORS.white,
    borderRadius: '10px',
    cursor: 'pointer',
  },
  filterMobileContent: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    width: 'auto',
    padding: '25px',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 15,
  },
  customDatesBox: {
    width: '100%',
    display: 'flex',
    gap: 10,
    marginTop: '10px',
  },
}));

export default styles;
