import { useQuery } from '@apollo/client';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { COLORS, PATHS } from '../../constants';
import { useAuth } from '../../helpers/AuthenticationProvider';
import { GET_USER_NAME } from '../../queries/authentication';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const Drawer = ({ logout }) => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const [pathname, setPathname] = useState('');
  const [user, setUser] = useState('');
  const { userId } = useAuth();

  const { data: userNameResult } = useQuery(GET_USER_NAME, {
    variables: {
      usersPermissionsUserId: userId,
    },
  });

  useEffect(() => {
    if (userNameResult && userNameResult?.usersPermissionsUser?.data?.attributes) {
      const username = userNameResult.usersPermissionsUser.data.attributes.name;
      setUser(username);
    }
  }, [userNameResult]);

  const getInitials = () => {
    let initials = '';
    if (user) {
      const words = user.split(' ');
      for (const word of words) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  };

  const drawerItems = [
    {
      label: t('sideBar.booking'),
      icon: <ClassOutlinedIcon style={{ fontSize: '20px' }} />,
      path: PATHS.bookings,
    },
    {
      label: t('sideBar.tour'),
      icon: <LocalActivityOutlinedIcon style={{ fontSize: '20px' }} />,
      path: PATHS.tours,
    },
    {
      label: t('sideBar.cruise'),
      icon: <DirectionsBoatOutlinedIcon style={{ fontSize: '20px' }} />,
      path: PATHS.cruise,
    },
    {
      label: t('sideBar.contact'),
      icon: <EmailOutlinedIcon style={{ fontSize: '20px' }} />,
      path: PATHS.contactForm,
    },
    {
      label: t('sideBar.config'),
      icon: <SettingsOutlinedIcon style={{ fontSize: '20px' }} />,
      path: PATHS.configuration,
    },
  ];

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    getInitials();
  }, [user]);

  return (
    <div className={classes.drawerWrapper}>
      <div className={classes.drawerContent}>
        <div className={classes.drawerList}>
          {drawerItems.map((item, index) => (
            <Link style={{ textDecoration: 'none' }} key={index} to={item.path}>
              <div
                className={pathname === item.path ? classes.drawerItemActive : classes.drawerItem}
              >
                <span className={classes.drawerButtonIconBox}>{item.icon}</span>
                <span className={fontClasses.dashBoardItemFont}>{item.label}</span>
              </div>
            </Link>
          ))}
        </div>
        <div className={classes.drawerProfile}>
          <hr className={classes.divider} />
          <div className={classes.drawerProfileContent}>
            <div className={classes.drawerItemProfile}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <span className={classes.avatar}>{getInitials()}</span>
                <span className={fontClasses.dashBoardItemFont}>{user}</span>
              </div>
              <IconButton className={classes.drawerButtonIconBox} onClick={logout}>
                <LogoutOutlinedIcon sx={{ color: COLORS.gray }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
