import { gql } from '@apollo/client';

const FORGET_PASSWORD_FORM = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export default FORGET_PASSWORD_FORM;
