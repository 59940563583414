import { gql } from '@apollo/client';

export const GET_CONTACT_FORMS = gql`
  query ContactForms($pagination: PaginationArg) {
    contactForms(pagination: $pagination, sort: "createdAt:desc") {
      data {
        id
        attributes {
          email
          full_name
          message
          subject
          createdAt
        }
      }
      meta {
        pagination {
          page
          pageCount
          pageSize
          total
        }
      }
    }
  }
`;

export const DELETE_CONTACT_FORM = gql`
  mutation DeleteContactForm($deleteContactFormId: ID!) {
    deleteContactForm(id: $deleteContactFormId) {
      data {
        id
      }
    }
  }
`;
