// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
// import breakpoints from '../../theme/breakpoints';

// const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  buttonStructure: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    padding: '0px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    '& p': {
      fontSize: '14px',
    },
    '& svg': {
      fontSize: '18px',
      margin: '0px',
    },
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed',
    '&:hover': {
      transform: 'none',
      opacity: 0.3,
    },
  },
  primaryStyle: {
    color: ` ${COLORS.green} !important`,
    '& p': {
      color: ` ${COLORS.green} !important`,
    },
  },
  secondaryStyle: {
    color: ` ${COLORS.orange} !important`,
    '& p': {
      color: ` ${COLORS.orange} !important`,
    },
  },
  tertiaryStyle: {
    color: ` ${COLORS.redTag} !important`,
    '& p': {
      color: ` ${COLORS.redTag} !important`,
    },
  },
}));

export default useStyles;
