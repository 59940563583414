import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import ViewListIcon from '@mui/icons-material/ViewList';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants';

const TabSelector = ({ isMobile, value, handleChange }) => {
  const { t } = useTranslation();

  return (
    <>
      {isMobile ? (
        <BottomNavigation showLabels={false} value={value} onChange={handleChange}>
          <BottomNavigationAction
            value={'today'}
            icon={<TodayIcon />}
            sx={{ color: COLORS.gray }}
          />
          <BottomNavigationAction
            value={'week'}
            icon={<DateRangeIcon />}
            sx={{ color: COLORS.gray }}
          />
          <BottomNavigationAction
            value={'month'}
            icon={<CalendarMonthIcon />}
            sx={{ color: COLORS.gray }}
          />
          <BottomNavigationAction value='all' icon={<ViewListIcon />} sx={{ color: COLORS.gray }} />
          <BottomNavigationAction
            value='custom'
            icon={<SettingsIcon />}
            sx={{ color: COLORS.gray }}
          />
        </BottomNavigation>
      ) : (
        <BottomNavigation
          showLabels={!isMobile}
          value={value}
          onChange={handleChange}
          sx={{ width: '550px' }}
        >
          <BottomNavigationAction
            label={t('tabSelector.today')}
            value={'today'}
            icon={<TodayIcon />}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1, color: COLORS.gray }}
          />
          <BottomNavigationAction
            label={t('tabSelector.week')}
            value={'week'}
            icon={<DateRangeIcon />}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1, color: COLORS.gray }}
          />
          <BottomNavigationAction
            label={t('tabSelector.month')}
            value={'month'}
            icon={<CalendarMonthIcon />}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1, color: COLORS.gray }}
          />
          <BottomNavigationAction
            label={t('tabSelector.all')}
            value={'all'}
            icon={<ViewListIcon />}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1, color: COLORS.gray }}
          />
          <BottomNavigationAction
            label={t('tabSelector.custom')}
            value='custom'
            icon={<SettingsIcon />}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1, color: COLORS.gray }}
          />
        </BottomNavigation>
      )}
    </>
  );
};

export default TabSelector;
