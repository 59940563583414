import moment from 'moment';

export function formatCruises(response) {
  return response.cruises.data.map((item) => ({
    id: item.id,
    name: item.attributes.name,
  }));
}

export function formatCruisesDates(response) {
  return response.cruiseDates.data.map((item) => ({
    id: item.id,
    eta: moment(item.attributes.eta, 'HH:mm:ss.SSS').format('hh:mm A'),
    etd: moment(item.attributes.etd, 'HH:mm:ss.SSS').format('hh:mm A'),
    date: item.attributes.date,
  }));
}
