import React from 'react';

import styles from './styles';

const ConfigurationPage = () => {
  const classes = styles();
  return <div className={classes.mainWrapper}>ConfigurationPage</div>;
};

export default ConfigurationPage;
