import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../constants';
import breakpoints from '../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const fontsStyles = makeStyles((theme) => ({
  h1: {
    fontSize: 40,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '40px',
    fontFamily: ['Poppins'].join(','),
    margin: 0,
    [themeBreakpoints.down('sm')]: {
      fontSize: 28,
      lineHeight: '33px',
    },
  },
  h2: {
    fontSize: 32,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '32px',
    margin: 0,
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '26px',
    },
  },
  h3: {
    fontSize: 26,
    fontWeight: 600,
    margin: 0,
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '24px',
    margin: '0px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  h5: {
    fontSize: 20,
    fontWeight: 600,
    color: COLORS.gray,
    lineHeight: '21px',
    margin: '0px',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  p: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.gray,
    lineHeight: 'normal',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  smallText: {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    color: COLORS.gray,
    lineHeight: '14px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  terms_conditionsFoms: {
    margin: 0,
    fontSize: 12,
    fontWeight: 300,
    color: COLORS.gray,
    lineHeight: '12px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  navbarItem: {
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    color: COLORS.gray,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.blue,
    },
  },
  navbarItemActive: {
    color: COLORS.blue,
  },
  footercolumnTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    color: COLORS.white,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  footercolumnText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.white,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    fontFamily: ['Poppins'].join(','),
    textDecoration: 'none',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '&:hover': {
      color: COLORS.yellow,
    },
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      whiteSpace: 'normal',
    },
  },
  sectionSubTitles: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    color: COLORS.blue,
    lineHeight: 'normal',
    fontFamily: ['Poppins'].join(','),
    textTransform: 'uppercase',
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
      whiteSpace: 'normal',
    },
  },
  primaryButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: ['Poppins'].join(','),
    textDecoration: 'none',
    [themeBreakpoints.down('sm')]: {
      fontSize: 15,
      margin: 0,
    },
  },
  justify: {
    textAlign: 'justify',
  },
  center: {
    textAlign: 'center',
  },
  linkText: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: COLORS.orange,
  },
  semiBold: {
    fontWeight: 600,
  },
  dashBoardItemFont: {
    fontFamily: ['Poppins'].join(','),
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      fontSize: 14,
    },
  },
}));

export default fontsStyles;
