import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import DrawerBox from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';

import Drawer from './components/Drawer';
import { PATHS, URL_IMAGES } from './constants';
import { useAuth } from './helpers/AuthenticationProvider';
import AddTourPage from './pages/Add-tour//AddTourPage';
import BookingPage from './pages/Booking/BookingPage';
import BookingDetailsPage from './pages/BookingDetails/BookingDetailsPage';
import ConfigurationPage from './pages/Configuration/ConfigurationPage';
import ContactFormPage from './pages/ContactForm/ContactFormPage';
import CruisePage from './pages/Cruise/CruisePage';
import Login from './pages/Login/loginPage';
import RecoveryPage from './pages/RecoveryPage/recoveryPage';
import ResetPassword from './pages/ResetPassword/resetPasswordPage';
import ToursPage from './pages/Tours/ToursPage';
import ToursDetailsPage from './pages/Tours-details/ToursDetailsPage';
import styles from './styles/styles';
import getTheme from './theme';

const App = () => {
  const classes = styles();
  const [drawerState, setDrawer] = useState(false);
  const theme = getTheme();
  const { token, clearToken, clearUserId } = useAuth();

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_STRAPI_GRAPHQL });

  const appolloOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  };

  const baseAuthLink = new ApolloLink((operation, forward) => {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`,
      },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const client = new ApolloClient({
    link: baseAuthLink.concat(httpLink), // Chain it with the HttpLink
    cache: new InMemoryCache(),
    defaultOptions: appolloOptions,
  });

  const tokenAuthLink = useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        // Use the setContext method to set the HTTP headers.
        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        // Call the next link in the middleware chain.
        return forward(operation);
      }),
    [token],
  );

  const authenticatedClient = useMemo(
    () =>
      new ApolloClient({
        link: tokenAuthLink.concat(httpLink), // Chain it with the HttpLink
        cache: new InMemoryCache(),
        defaultOptions: appolloOptions,
      }),
    [token],
  );

  const openDrawer = () => {
    drawerState ? setDrawer(false) : setDrawer(true);
  };

  const closeDrawer = () => {
    !drawerState ? setDrawer(true) : setDrawer(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setDrawer(false);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the state based on the initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const logout = () => {
    clearToken();
    clearUserId();
    setDrawer(false);
  };

  const DashboardLayout = () => {
    if (!token) {
      return <Navigate to={PATHS.login} />;
    }
    return (
      <Box className={classes.mainWrapper}>
        <Box className={classes.drawerWrapper}>
          <Box className={classes.drawerLogo}>
            <Box className={classes.iconHamburger} sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton aria-label='delete' onClick={openDrawer}>
                <MenuIcon />
              </IconButton>
            </Box>
            <img
              className={classes.logoImage}
              src={URL_IMAGES.URL_LOGO_CARRIBEAN_ADVENTURES}
              alt='logo'
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Drawer logout={logout} />
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <DrawerBox
              anchor={'left'}
              open={drawerState}
              onClose={closeDrawer}
              sx={{ zIndex: 400 }}
            >
              <Drawer logout={logout} />
            </DrawerBox>
          </Box>
        </Box>
        <Box className={classes.routesWrapper}>
          <Outlet />
        </Box>
      </Box>
    );
  };

  const NonAuthOnlyRoute = () => {
    if (token) {
      return <Navigate to={PATHS.bookings} />;
    }

    return <Outlet />;
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <NonAuthOnlyRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: PATHS.login,
          element: <Login />,
        },
        {
          path: PATHS.recovery,
          element: <RecoveryPage />,
        },
        {
          path: PATHS.resetPassword,
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: PATHS.bookings,
          element: <BookingPage />,
        },
        {
          path: PATHS.bookingDetails,
          element: <BookingDetailsPage />,
        },
        {
          path: PATHS.addTour,
          element: <AddTourPage />,
        },
        {
          path: PATHS.tours,
          element: <ToursPage />,
        },
        {
          path: PATHS.toursDetails,
          element: <ToursDetailsPage />,
        },
        {
          path: PATHS.cruise,
          element: <CruisePage />,
        },
        {
          path: PATHS.contactForm,
          element: <ContactFormPage />,
        },
        {
          path: PATHS.configuration,
          element: <ConfigurationPage />,
        },
      ],
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={token ? authenticatedClient : client}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};
export default App;
