import { useQuery } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Fab, Grid, Pagination, Skeleton } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import CustomPrimaryButton from '../../components/CustomButton';
import TourCard from '../../components/TourCard';
import { COLORS, PATHS } from '../../constants';
import { formatTour } from '../../helpers/interfaces/tourInterface';
import { GET_TOURS } from '../../queries/tours';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import styles from './styles';

const ToursPage = () => {
  const navigate = useNavigate();
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });

  const handleNewTour = (event, value) => {
    navigate(PATHS.addTour);
  };

  const ToursSection = () => {
    const [toursList, setToursList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const {
      data: toursData,
      loading: loadingData,
      error: errorTours,
    } = useQuery(GET_TOURS, { variables: { sort: 'name:asc' } });

    const handlePageChange = (event, value) => {
      setPageNumber(value);
    };

    useEffect(() => {
      if (toursData) {
        setToursList(formatTour(toursData));
        setPageCount(toursData.tours.meta.pagination.pageCount);
      }
    }, [toursData]);

    return (
      <div className={classes.section}>
        <div className={classes.mainTitle}>
          <h2 className={fontClasses.h3}>{t('toursPage.tours')}</h2>
          {!isMobile && (
            <div className={classes.buttonDiv}>
              <CustomPrimaryButton
                label={t('toursPage.new')}
                tertiary
                icon={<AddOutlinedIcon />}
                onClick={handleNewTour}
              />
            </div>
          )}
        </div>
        <div className={classes.bookingList}>
          {toursList.length > 0 && !loadingData && !errorTours && (
            <>
              {!isMobile && (
                <div className={classes.headerTableBox}>
                  <Grid container sx={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('toursPage.name')}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('toursPage.price')}
                      </p>
                    </Grid>
                  </Grid>
                </div>
              )}
              {toursList.map((item, index) => {
                return <TourCard key={'cardForm-' + index} tourData={item} />;
              })}
              {pageCount > 1 && isMobile && (
                <div className={classes.paginationStyle}>
                  <Pagination
                    count={pageCount}
                    color='primary'
                    page={pageNumber}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </>
          )}
          {errorTours && (
            <div className={classes.emptyList}>
              <div className={classes.emptyMessageBox}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: '50px', color: COLORS.gray }} />
                <h2 className={fontClasses.h2}>{t('validationsForm.errorTitle')}</h2>
                <p className={fontClasses.p}>{t('validationsForm.errorMessage')}</p>
              </div>
            </div>
          )}
          {!loadingData && !errorTours && toursList.length === 0 && (
            <div className={classes.emptyList}>
              <div className={classes.emptyMessageBox}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: '50px', color: COLORS.gray }} />
                <p className={fontClasses.p}>{t('bookingPage.emptyMessage')}</p>
              </div>
            </div>
          )}
          {loadingData && (
            <div className={classes.loadingBox}>
              {[...Array(4)].map((__, index) => (
                <Skeleton
                  key={'skeleton-booking-' + index}
                  variant='rectangular'
                  width='100%'
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '50px',
                  }}
                />
              ))}
            </div>
          )}
        </div>
        {pageCount > 1 && !isMobile && (
          <div className={classes.paginationStyle}>
            <Pagination
              count={pageCount}
              color='primary'
              page={pageNumber}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.mainWrapper}>
      <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={10}>
          <ToursSection />
        </Grid>
      </Grid>
      {isMobile && (
        <Fab
          color='secondary'
          aria-label='add'
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
          onClick={handleNewTour}
        >
          <AddOutlinedIcon sx={{ color: COLORS.white }} />
        </Fab>
      )}
    </div>
  );
};

export default ToursPage;
