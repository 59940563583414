import { gql } from '@apollo/client';

export const GET_BOOKINGS = gql`
  query Bookings($pagination: PaginationArg, $sort: [String], $filters: BookingFiltersInput) {
    bookings(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          booking_date
          booking_status {
            data {
              attributes {
                description
                status
              }
              id
            }
          }
          code
          createdAt
          cruise_id {
            data {
              attributes {
                name
              }
            }
          }
          payment_type {
            data {
              attributes {
                type
              }
            }
          }
          quantity
          service_fee
          sub_total
          total
          tour {
            data {
              attributes {
                name
                price
              }
            }
          }
          user_email
          user_first_name
          user_last_name
          user_phone
        }
      }
      meta {
        pagination {
          pageCount
        }
      }
    }
  }
`;

export const GET_BOOKING_BY_CODE = gql`
  query Bookings($filters: BookingFiltersInput) {
    bookings(filters: $filters) {
      meta {
        pagination {
          pageCount
        }
      }
      data {
        id
        attributes {
          booking_date
          booking_status {
            data {
              attributes {
                description
                status
              }
              id
            }
          }
          code
          createdAt
          cruise_id {
            data {
              attributes {
                name
              }
            }
          }
          payment_type {
            data {
              attributes {
                type
              }
            }
          }
          quantity
          service_fee
          sub_total
          total
          tour {
            data {
              attributes {
                name
                price
              }
            }
          }
          user_email
          user_first_name
          user_last_name
          user_phone
        }
      }
    }
  }
`;

export const GET_BOOKING_PAYMENT_BY_CODE = gql`
  query Data($filters: PaymentFiltersInput) {
    payments(filters: $filters) {
      data {
        attributes {
          description
          authorization
          brand
          cancel
          crd
          gateway_transaction
          last_digits
          padded
          status_code
          tpt
          validate_payment
          order_hash
        }
      }
    }
  }
`;

export const GET_BOOKING_HISTORY_BY_CODE = gql`
  query Attributes($filters: BookingStatusLogFiltersInput) {
    bookingStatusLogs(filters: $filters) {
      data {
        attributes {
          booking_id
          current_status
          custom_updated_by
          updatedAt
        }
      }
    }
  }
`;

export const CHANGE_STATE_BY_CODE = gql`
  mutation UpdateBooking($updateBookingId: ID!, $data: BookingInput!) {
    updateBooking(id: $updateBookingId, data: $data) {
      data {
        attributes {
          booking_status {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
