import { formatDate } from '../dates';

export function formatContact(response) {
  return response.contactForms.data.map((item) => ({
    id: item.id,
    email: item.attributes.email,
    name: item.attributes.full_name,
    subject: item.attributes.subject,
    message: item.attributes.message,
    date: formatDate(item.attributes.createdAt),
  }));
}
