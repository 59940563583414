import { COLORS } from '../constants';

const palette = {
  type: 'ligth',
  primary: {
    main: COLORS.green,
  },
  secondary: {
    main: COLORS.orange,
  },
  common: {
    black: COLORS.orange, // Color negro
  },
  white: {
    main: '#FFFFFF',
  },
};

export default palette;
