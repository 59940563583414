import { makeStyles } from '@material-ui/styles';

const styles = makeStyles((theme) => ({
  boxModal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
    gap: 10,
    backgroundColor: 'white',
  },
  modalActionsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
}));

export default styles;
