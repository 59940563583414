import { useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
} from '@mui/material';
import {
  DesktopDatePicker,
  DesktopTimePicker,
  MobileDatePicker,
  MobileTimePicker,
} from '@mui/x-date-pickers';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import CustomButton from '../../components/CustomButton';
import TextButton from '../../components/TextButton';
import { COLORS } from '../../constants';
import { useAlert } from '../../helpers/AlertProvider';
import { formatCruisesDates } from '../../helpers/interfaces/cruiseInterface';
import { ADD_CRUISE_DATE, DELETE_CRUISE_DATE, GET_DATES_BY_CRUISE } from '../../queries/cruises';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import Modal from '../Modal/Modal';
import styles from './styles';

const CruiseCard = ({
  cruiseData,
  deleteHandle,
  acordionExpanded,
  setAcordionExpanded,
  loadingDeleteCruise,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = styles();
  const fontClasses = fontsStyles();
  const [cruisesDates, setCruisesDates] = useState([]);
  const [newDateModal, setNewDateModal] = useState(false);
  const showAlert = useAlert();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });
  const [getDates, { data: cruisesDateData, loading: loadingData, error: errorCruisesDates }] =
    useLazyQuery(GET_DATES_BY_CRUISE, {
      variables: {
        filters: {
          cruise_id: {
            id: {
              eq: cruiseData.id,
            },
          },
        },
        sort: 'date:asc',
      },
    });

  const [deleteCruiseDate] = useMutation(DELETE_CRUISE_DATE, {
    onError: () => {
      showAlert(t('cruisesPage.deleleDateErrorMeesage'), 'error');
    },
    onCompleted: () => {
      getDates();
      showAlert(t('cruisesPage.deleleDateSuccesMeesage'), 'success');
    },
  });

  useEffect(() => {
    if (cruisesDateData) {
      setCruisesDates(formatCruisesDates(cruisesDateData));
    }
  }, [cruisesDateData, errorCruisesDates, loadingData]);

  useEffect(() => {
    if (acordionExpanded === cruiseData.id) {
      getDates();
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [acordionExpanded]);

  const handleChangeModalState = () => {
    setNewDateModal(!newDateModal);
  };

  const handleSuccesAddDate = () => {
    handleChangeModalState();
    getDates();
    showAlert(t('cruisesPage.addDateSuccesMeesage'), 'success');
  };
  const hanldeErrorAddDate = () => {
    handleChangeModalState();
    showAlert(t('cruisesPage.addDateErrorMeesage'), 'error');
  };

  const ModalContent = ({
    handleSuccesAddDate,
    hanldeErrorAddDate,
    handleChangeModalState,
    cruiseName,
  }) => {
    const [dateValue, setDateValue] = useState(dayjs());
    const [etaValue, setEtaValue] = useState(dayjs().hour(8).minute(0));
    const [etdValue, setEtdValue] = useState(dayjs().hour(16).minute(0));
    const [addDateMutation, { loading }] = useMutation(ADD_CRUISE_DATE, {
      onError: () => {
        hanldeErrorAddDate();
      },
      onCompleted: () => {
        handleSuccesAddDate();
      },
    });

    const disableEtd = (time) => {
      return dayjs(time).isBefore(etaValue);
    };

    const disableEta = (time) => {
      return dayjs(time).isAfter(etdValue);
    };

    const handleNewDateButton = () => {
      addDateMutation({
        variables: {
          data: {
            cruise_id: cruiseData.id,
            date: dateValue.format('YYYY-MM-DD'),
            eta: etaValue.format('HH:mm:ss.SSS'),
            etd: etdValue.format('HH:mm:ss.SSS'),
          },
        },
      });
    };

    return (
      <div className={classes.modalContent}>
        <div className={classes.closeButton}>
          <IconButton size='large' onClick={handleChangeModalState}>
            <CloseIcon sx={{ color: COLORS.gray, fontSize: '28px' }} />
          </IconButton>
        </div>
        <p className={fontClasses.h3} style={{ textAlign: 'center', width: '90%' }}>
          {t('cruisesPage.addNewDate')} {cruiseName}
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.textFieldBox}>
              <p className={clsx(fontClasses.p, fontClasses.semiBold)}>{t('cruisesPage.date')}</p>
              {!isMobile && (
                <DesktopDatePicker
                  sx={{ width: '100%' }}
                  value={dateValue}
                  onChange={(newValue) => setDateValue(newValue)}
                />
              )}
              {isMobile && (
                <MobileDatePicker
                  sx={{ width: '100%' }}
                  value={dateValue}
                  onChange={(newValue) => setDateValue(newValue)}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={classes.textFieldBox}>
              <p className={clsx(fontClasses.p, fontClasses.semiBold)}>{t('cruisesPage.eta')}</p>
              {!isMobile && (
                <DesktopTimePicker
                  sx={{ width: '100%' }}
                  ampm={false}
                  value={etaValue}
                  onChange={(newValue) => setEtaValue(newValue)}
                  shouldDisableTime={disableEta}
                />
              )}
              {isMobile && (
                <MobileTimePicker
                  sx={{ width: '100%' }}
                  ampm={false}
                  value={etaValue}
                  onChange={(newValue) => setEtaValue(newValue)}
                  shouldDisableTime={disableEta}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={classes.textFieldBox}>
              <p className={clsx(fontClasses.p, fontClasses.semiBold)}>{t('cruisesPage.etd')}</p>
              {!isMobile && (
                <DesktopTimePicker
                  sx={{ width: '100%' }}
                  ampm={false}
                  value={etdValue}
                  onChange={(newValue) => setEtdValue(newValue)}
                  shouldDisableTime={disableEtd}
                />
              )}
              {isMobile && (
                <MobileTimePicker
                  sx={{ width: '100%' }}
                  ampm={false}
                  value={etdValue}
                  onChange={(newValue) => setEtdValue(newValue)}
                  shouldDisableTime={disableEtd}
                />
              )}
            </div>
          </Grid>
        </Grid>
        {loading && (
          <div style={{ dis: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color='primary' />
          </div>
        )}
        <CustomButton
          label={t('cruisesPage.btnAddDate')}
          fullWidth={isMobile}
          onClick={handleNewDateButton}
          disabled={loading}
        />
      </div>
    );
  };

  return (
    <Accordion
      className={classes.tableElement}
      elevation={0}
      expanded={acordionExpanded === cruiseData.id}
      square
      disableGutters
      sx={{
        width: '100%',
        backgroundColor: isExpanded ? COLORS.lightLime : COLORS.white,
      }}
      onChange={(e, expanded) => {
        if (expanded) {
          setAcordionExpanded(cruiseData.id);
        } else {
          setAcordionExpanded(false);
        }
      }}
    >
      <AccordionSummary
        sx={{
          '.MuiAccordionSummary-expandIconWrapper': {
            padding: '10px', // Aplica padding al icono
          },
          '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            padding: '10px', // Asegura que el padding se mantenga al abrir
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <p
          style={{ padding: '0px 20px' }}
          className={clsx(fontClasses.p, isExpanded ? fontClasses.semiBold : '')}
        >
          {cruiseData.name}
        </p>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px 20px 20px' }}>
        {cruisesDates.length > 0 && (
          <Grid container sx={{ marginBottom: '15px' }}>
            <Grid item xs={5} sm={5} md={3} lg={2} xl={2}>
              <p className={clsx(fontClasses.p, fontClasses.smallText, fontClasses.semiBold)}>
                {t('cruisesPage.date')}
              </p>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
              <p className={clsx(fontClasses.p, fontClasses.smallText, fontClasses.semiBold)}>
                {!isMobile ? t('cruisesPage.eta') : t('cruisesPage.etaSmall')}
              </p>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
              <p className={clsx(fontClasses.p, fontClasses.smallText, fontClasses.semiBold)}>
                {!isMobile ? t('cruisesPage.etd') : t('cruisesPage.etaSmall')}
              </p>
            </Grid>
            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
              <p className={clsx(fontClasses.p, fontClasses.smallText, fontClasses.semiBold)}>
                {!isMobile ? t('cruisesPage.actions') : ''}
              </p>
            </Grid>
          </Grid>
        )}
        {!loadingData &&
          cruisesDates.map((item, index) => {
            return (
              <Grid key={index} container sx={{ marginTop: '10px' }}>
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={3}
                  lg={2}
                  xl={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <p className={clsx(fontClasses.p, fontClasses.smallText)}>
                    {dayjs(item.date).format('MMMM D, YYYY')}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={2}
                  xl={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <p className={clsx(fontClasses.p, fontClasses.smallText)}>{item.eta}</p>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={2}
                  xl={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <p className={clsx(fontClasses.p, fontClasses.smallText)}>{item.etd}</p>
                </Grid>
                <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    size='small'
                    sx={{ color: COLORS.redTag }}
                    onClick={() =>
                      deleteCruiseDate({
                        variables: {
                          deleteCruiseDateId: item.id,
                        },
                      })
                    }
                  >
                    <DeleteIcon sx={{ fontSize: '15px' }} />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        {loadingData &&
          [...Array(2)].map((__, index) => (
            <Skeleton
              key={'skeleton-booking-' + index}
              variant='rectangular'
              width='100%'
              sx={{
                borderRadius: '10px',
                width: '20%',
                height: '20px',
                marginBottom: '5px',
              }}
            />
          ))}
        {!loadingData && (
          <div className={classes.actionButtonsBox}>
            <TextButton
              label={t('cruisesPage.btnAddDate')}
              icon={<AddIcon />}
              secondary
              onClick={handleChangeModalState}
            />
            {cruisesDates.length === 0 && (
              <TextButton
                label={t('cruisesPage.btnDeleteCruise')}
                icon={<DeleteIcon />}
                disabled={loadingDeleteCruise}
                tertiary
                onClick={() =>
                  deleteHandle({
                    variables: {
                      deleteCruiseId: cruiseData.id,
                    },
                  })
                }
              />
            )}
          </div>
        )}
        <Modal
          pModalOpen={newDateModal}
          pHandleClose={handleChangeModalState}
          pModalContent={
            <ModalContent
              handleSuccesAddDate={handleSuccesAddDate}
              hanldeErrorAddDate={hanldeErrorAddDate}
              handleChangeModalState={handleChangeModalState}
              cruiseName={cruiseData.name}
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CruiseCard;
