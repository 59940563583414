import { Dialog, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import breakpoints from '../../theme/breakpoints';

const Modal = ({ pModalOpen, pHandleClose, pModalContent }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });

  return (
    <>
      {!isMobile ? (
        <Dialog open={pModalOpen} sx={{ zIndex: 6000 }} maxWidth='md' onClose={pHandleClose}>
          {pModalContent}
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor={'bottom'}
          open={pModalOpen}
          onClose={pHandleClose}
          PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
          sx={{ zIndex: 6000 }}
        >
          {pModalContent}
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Modal;
