import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const ContactCard = ({
  id,
  name,
  email,
  date,
  subject,
  message,
  setDeleteID,
  setOpenDeleteModal,
}) => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();

  const handleOpenModal = () => {
    setDeleteID(id);
    setOpenDeleteModal(true);
  };

  const handleClickMail = () => {
    window.location.href = `mailto:${email}?subject=RE/${subject}`;
  };

  return (
    <div className={classes.mainCard}>
      <div className={classes.contentSection}>
        <div className={classes.fromSection}>
          <div className={classes.fromSectionContent}>
            <h5 className={fontClasses.h5}>{name}</h5>
            <p className={clsx(fontClasses.p, classes.emailText)}>{email}</p>
          </div>
          <div className={classes.buttonsSection}>
            <p className={clsx(fontClasses.p, classes.dateText)}>{date}</p>
            <div className={classes.buttonsSectionAux}>
              <IconButton color='primary' onClick={handleClickMail}>
                <ReplyIcon />
              </IconButton>
              <IconButton color='error' onClick={handleOpenModal}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <p className={clsx(fontClasses.p, classes.fullText)}>
          <span className={classes.subjectText}>{t('contactPage.subject')}: </span>
          {subject}
        </p>
        <p className={clsx(fontClasses.p)}>{message}</p>
      </div>
    </div>
  );
};

export default ContactCard;
