import { Grid } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import Tag from '../Tag';
import styles from './styles';

const BookingCard = ({ bookingData }) => {
  const navigate = useNavigate();
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });

  const handleClickBooking = () => {
    navigate(PATHS.bookingDetails, { state: { booking: bookingData } });
  };

  return (
    <div className={classes.tableElement} onClick={handleClickBooking}>
      <Grid container>
        <Grid item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ display: 'flex' }}>
          <p className={clsx(fontClasses.p)}>
            {isMobile && <span className={fontClasses.semiBold}>{t('bookingPage.name')}: </span>}
            {bookingData.client}
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <p className={clsx(fontClasses.p)}>
            {isMobile && <span className={fontClasses.semiBold}>{t('bookingPage.code')}: </span>}
            {bookingData.bookingCode}
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <p className={clsx(fontClasses.p)}>
            {isMobile && (
              <span className={fontClasses.semiBold}>{t('bookingPage.bookingDate')}: </span>
            )}
            {bookingData.bookingDate}
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
          <p className={clsx(fontClasses.p)}>
            {isMobile && (
              <span className={fontClasses.semiBold}>{t('bookingPage.quantity')}: </span>
            )}
            {bookingData.quantity}
          </p>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
          <p className={clsx(fontClasses.p)}>
            {isMobile && <span className={fontClasses.semiBold}>{t('bookingPage.total')}: </span>}$
            {bookingData.total}
          </p>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
          <div className={classes.statusBox}>
            <Tag type={bookingData.bookingStatus} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BookingCard;
