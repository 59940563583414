import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import breakpoints from '../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 0,
    [themeBreakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  drawerWrapper: {
    height: '100vh',
    width: '230px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 0,
    backgroundColor: 'white',
    [themeBreakpoints.down('sm')]: {
      borderRight: 'none',
      borderBottom: 'solid 1px #E8E8EE',
      zIndex: 500,
      width: '100%',
      height: 'auto',
    },
  },
  routesWrapper: {
    overflowY: 'hidden',
    height: '100vh',
    width: 'calc(100% - 230px)',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      height: 'calc(100vh - 75px)',
    },
  },
  drawerLogo: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 30px',
    [themeBreakpoints.down('sm')]: {
      padding: '15px 15px',
    },
  },
  logoImage: {
    width: '100%',
    [themeBreakpoints.down('sm')]: {
      width: '110px',
    },
  },
  iconHamburger: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export default styles;
