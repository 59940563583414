import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  tableElement: {
    borderBottom: '1px solid #E8E8EE',
    padding: '10px 0px',
    margin: '0px 20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    '&:last-child': {
      borderBottom: 'none', // Eliminar el borde del último elemento
    },
    '&:hover': {
      backgroundColor: COLORS.lightGreen,
    },
    [themeBreakpoints.down('sm')]: {
      padding: '20px 0px',
    },
  },
  statusBox: {
    width: 'auto',
    display: 'flex',
    [themeBreakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

export default styles;
