import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import ViewListIcon from '@mui/icons-material/ViewList';
import { FormControlLabel, IconButton, Radio, RadioGroup, SwipeableDrawer } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const MobileFilterSelector = ({
  value,
  handleChange,
  dateRange1,
  dateRange2,
  handleRange1Change,
  handleRange2Change,
}) => {
  const { t } = useTranslation();
  const classes = styles();
  const fontClasses = fontsStyles();
  const [openFilterSelector, setOpenFilterSelector] = useState(false);

  const handleChangeFilterSelector = () => {
    setOpenFilterSelector(!openFilterSelector);
  };

  return (
    <>
      <div className={classes.mobileFiltersBox} onClick={handleChangeFilterSelector}>
        {value === 'today' && (
          <>
            <TodayIcon sx={{ color: COLORS.gray, fontSize: '20px' }} />
            <p className={fontClasses.p}>{t('tabSelector.today')}</p>
          </>
        )}
        {value === 'week' && (
          <>
            <DateRangeIcon />
            <p className={fontClasses.p}>{t('tabSelector.week')}</p>
          </>
        )}
        {value === 'month' && (
          <>
            <CalendarMonthIcon />
            <p className={fontClasses.p}>{t('tabSelector.month')}</p>
          </>
        )}
        {value === 'all' && (
          <>
            <ViewListIcon />
            <p className={fontClasses.p}>{t('tabSelector.all')}</p>
          </>
        )}
        {value === 'custom' && (
          <>
            <SettingsIcon />
            <p className={fontClasses.p}>{t('tabSelector.custom')}</p>
          </>
        )}
      </div>
      <SwipeableDrawer
        anchor={'bottom'}
        open={openFilterSelector}
        onClose={handleChangeFilterSelector}
        PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
        sx={{ zIndex: 1000 }}
      >
        <div className={classes.filterMobileContent}>
          <div className={classes.closeButton}>
            <IconButton size='large' onClick={handleChangeFilterSelector}>
              <CloseIcon sx={{ color: COLORS.gray, fontSize: '28px' }} />
            </IconButton>
          </div>
          <p className={fontClasses.h3}>{t('bookingPage.filterDates')}</p>
          <div style={{ width: '100%' }}>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value='today' control={<Radio />} label={t('tabSelector.today')} />
              <FormControlLabel value='week' control={<Radio />} label={t('tabSelector.week')} />
              <FormControlLabel value='month' control={<Radio />} label={t('tabSelector.month')} />
              <FormControlLabel value='all' control={<Radio />} label={t('tabSelector.all')} />
              <FormControlLabel
                value='custom'
                control={<Radio />}
                label={t('tabSelector.custom')}
              />
            </RadioGroup>
            {value === 'custom' && (
              <div className={classes.customDatesBox}>
                <MobileDatePicker
                  label={t('bookingPage.from')}
                  orientation='portrait'
                  autoFocus={false}
                  timezone='America/Costa_Rica'
                  slotProps={{
                    inputAdornment: {
                      position: 'start',
                      id: 'calendarButton',
                    },
                  }}
                  value={dateRange1}
                />
                <MobileDatePicker
                  label={t('bookingPage.to')}
                  orientation='portrait'
                  autoFocus={false}
                  timezone='America/Costa_Rica'
                  slotProps={{
                    inputAdornment: {
                      position: 'start',
                      id: 'calendarButton',
                    },
                  }}
                  value={dateRange2}
                  onChange={handleRange2Change}
                />
              </div>
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileFilterSelector;
