import { Dialog, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import CustomPrimaryButton from '../../components/CustomButton';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import styles from './styles';

const ConfirmationModal = ({
  open,
  title,
  text,
  labelApproveButton,
  labelRejectButton,
  handlerApproveButton,
  handlerRejectButton,
}) => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });

  const Content = () => {
    return (
      <div className={classes.boxModal}>
        <h4 className={fontClasses.h4}>{title}</h4>
        <p className={fontClasses.p}>{text}</p>
        <div className={classes.modalActionsBox}>
          <CustomPrimaryButton secondary label={labelRejectButton} onClick={handlerRejectButton} />
          <CustomPrimaryButton label={labelApproveButton} onClick={handlerApproveButton} />
        </div>
      </div>
    );
  };

  return (
    <>
      {!isMobile ? (
        <Dialog
          open={open}
          onClose={handlerRejectButton}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <Content />
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor={'bottom'}
          open={open}
          onClose={handlerRejectButton}
          PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
          sx={{ zIndex: 6000 }}
        >
          <Content />
        </SwipeableDrawer>
      )}
      ;
    </>
  );
};

export default ConfirmationModal;
