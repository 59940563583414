import clsx from 'clsx';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const TextButton = ({
  label,
  icon,
  url,
  disabled,
  onClick,
  fullWidth,
  secondary,
  tertiary,
  extraClasses,
  state,
  submit,
}) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  const TypeButtonClass = () => {
    let classStyle;

    if (secondary) {
      classStyle = classes.secondaryStyle;
    } else if (tertiary) {
      classStyle = classes.tertiaryStyle;
    } else {
      classStyle = classes.primaryStyle;
    }
    return classStyle;
  };

  const ButtonStructure = () => {
    return (
      <button
        className={clsx(
          classes.buttonStructure,
          extraClasses,
          TypeButtonClass(),
          disabled ? classes.disabled : '',
        )}
        onClick={onClick || null}
        disabled={disabled}
        style={{ width: fullWidth ? '100%' : 'auto' }}
        type={submit ? 'submit' : null}
      >
        {icon && <>{icon}</>}
        {label && <p className={clsx(fontClasses.p)}>{label}</p>}
      </button>
    );
  };

  return (
    <>
      {url && (
        <Link to={url} style={{ textDecoration: 'none', width: '100%' }} state={state || null}>
          <ButtonStructure />
        </Link>
      )}
      {!url && <ButtonStructure />}
    </>
  );
};

export default TextButton;
