import { Grid } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import styles from './styles';

const TourCard = ({ tourData }) => {
  const navigate = useNavigate();
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });

  const handleClickBooking = () => {
    navigate(PATHS.toursDetails, { state: { booking: tourData } });
  };

  return (
    <div className={classes.tableElement} onClick={handleClickBooking}>
      <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} sx={{ display: 'flex' }}>
          <p className={clsx(fontClasses.p)}>
            {isMobile && <span className={fontClasses.semiBold}>{t('toursPage.name')}: </span>}
            {tourData.name}
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <p className={clsx(fontClasses.p)}>
            {isMobile && <span className={fontClasses.semiBold}>{t('toursPage.price')}: </span>}$
            {tourData.price}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default TourCard;
