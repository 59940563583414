import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  mainWrapper: {
    height: '100vh',
    overflowY: 'hidden',
  },
  section: {
    height: '100vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '50px 25px',
    borderRight: 'solid 1px #E8E8EE',
    '&::-webkit-scrollbar': {
      height: '3px',
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      backgroundColor: '#FFFFFF',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-track:active': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: COLORS.scrollbar,
    },
    [themeBreakpoints.down('sm')]: {
      height: 'calc(100vh - 75px)',
      padding: '25px 25px',
    },
  },
  mainTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 10,
  },
  messageList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    gap: 20,
  },
  emptyList: {
    paddingTop: '150px',
  },
  emptyMessageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  paginationBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default styles;
