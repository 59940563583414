import { formatDate } from '../dates';

export function formatBooking(response, t) {
  console.log(response);
  return response.bookings.data.map((item) => ({
    id: item.id,
    client: `${item.attributes.user_first_name} ${item.attributes.user_last_name}`,
    clientFirstName: item.attributes.user_first_name,
    clientLastName: item.attributes.user_last_name,
    userEmail: item.attributes.user_email,
    userPhone: item.attributes.user_phone,
    tourName: item.attributes.tour.data.attributes.name,
    bookingStatus: item.attributes.booking_status.data.attributes.status,
    bookingCode: item.attributes.code,
    bookingDate: item.attributes.booking_date,
    createdAt: formatDate(item.attributes.createdAt),
    cruise:
      item.attributes.cruise_id.data === null
        ? t('bookingPage.emptyCruise')
        : item.attributes.cruise_id.data.attributes.name,
    paymentType: item.attributes.payment_type?.data.attributes.type,
    tourPrice: item.attributes.tour.data.attributes.price,
    quantity: item.attributes.quantity,
    serviceFee: item.attributes.service_fee,
    subTotal: item.attributes.sub_total,
    total: item.attributes.total,
  }));
}

export function formatOneBooking(response, t) {
  return {
    id: response.bookings.data[0].id,
    client: `${response.bookings.data[0].attributes.user_first_name} ${response.bookings.data[0].attributes.user_last_name}`,
    clientFirstName: response.bookings.data[0].attributes.user_first_name,
    clientLastName: response.bookings.data[0].attributes.user_last_name,
    userEmail: response.bookings.data[0].attributes.user_email,
    userPhone: response.bookings.data[0].attributes.user_phone,
    tourName: response.bookings.data[0].attributes.tour.data.attributes.name,
    bookingStatus: response.bookings.data[0].attributes.booking_status.data.attributes.status,
    bookingCode: response.bookings.data[0].attributes.code,
    bookingDate: response.bookings.data[0].attributes.booking_date,
    createdAt: formatDate(response.bookings.data[0].attributes.createdAt),
    cruise:
      response.bookings.data[0].attributes.cruise_id.data?.attributes.name ||
      t('bookingPage.emptyCruise'),
    paymentType: response.bookings.data[0].attributes.payment_type.data.attributes.type,
    tourPrice: response.bookings.data[0].attributes.tour.data.attributes.price,
    quantity: response.bookings.data[0].attributes.code,
    serviceFee: response.bookings.data[0].attributes.service_fee,
    subTotal: response.bookings.data[0].attributes.sub_total,
    total: response.bookings.data[0].attributes.total,
  };
}
