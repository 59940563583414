import './styles/index.css';
import './i18n';

import { createRoot } from 'react-dom/client';

import App from './App';
import { AlertProvider } from './helpers/AlertProvider';
import AuthProvider from './helpers/AuthenticationProvider';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <AuthProvider>
    <AlertProvider>
      <App />
    </AlertProvider>
  </AuthProvider>,
);
