import { useMutation, useQuery } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Pagination,
  Skeleton,
  TextField,
} from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import CruiseCard from '../../components/CruiseCard';
import CustomPrimaryButton from '../../components/CustomButton';
import Modal from '../../components/Modal/Modal';
import { COLORS } from '../../constants';
import { useAlert } from '../../helpers/AlertProvider';
import { formatCruises } from '../../helpers/interfaces/cruiseInterface';
import { ADD_NEW_CRUISE, DELETE_CRUISE, GET_CRUISES } from '../../queries/cruises';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import styles from './styles';

const CruisePage = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });
  const { t } = useTranslation();

  const CruiseSection = () => {
    const showAlert = useAlert();
    const [newCruiseModal, setNewCruisModal] = useState(false);
    const [cruisesList, setCruisesList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [acordionExpanded, setAcordionExpanded] = useState(1);
    const {
      data: cruisesData,
      loading: loadingData,
      error: errorCruises,
      refetch,
    } = useQuery(GET_CRUISES, {
      variables: {
        pagination: {
          page: pageNumber,
          pageSize: 20,
        },
      },
    });

    const [deleteCruise, { loading: loadingDeleteCruise }] = useMutation(DELETE_CRUISE, {
      onError: () => {
        showAlert(t('cruisesPage.deleteErrorCruise'), 'error');
      },
      onCompleted: () => {
        handleDeleteCruises();
      },
    });

    const handlePageChange = (event, value) => {
      setPageNumber(value);
    };

    const handleDeleteCruises = () => {
      setAcordionExpanded(false);
      showAlert(t('cruisesPage.deleteSuccessCruise'), 'success');
      refetch();
    };

    const handleChangeModalState = () => {
      setNewCruisModal(!newCruiseModal);
    };

    const handleSuccesAddCruise = () => {
      handleChangeModalState();
      showAlert(t('cruisesPage.addSuccessCruise'), 'success');
      refetch();
    };

    const handleErrorAddCruise = () => {
      handleChangeModalState();
      showAlert(t('cruisesPage.addErrorCruise'), 'error');
    };

    useEffect(() => {
      if (cruisesData) {
        setCruisesList(formatCruises(cruisesData));
        setPageCount(cruisesData.cruises.meta.pagination.pageCount);
      }
    }, [cruisesData]);

    const ModalContent = ({
      handleSuccesAddCruise,
      handleErrorAddCruise,
      handleChangeModalState,
    }) => {
      const [cruisName, setCruiseName] = useState('');
      const [addCruiseMutation, { loading }] = useMutation(ADD_NEW_CRUISE, {
        onError: () => {
          handleErrorAddCruise();
        },
        onCompleted: () => {
          handleSuccesAddCruise();
        },
      });

      const handleNewCruiseButton = () => {
        addCruiseMutation({
          variables: {
            data: {
              name: cruisName,
              publishedAt: new Date().toISOString(),
            },
          },
        });
      };

      return (
        <div className={classes.modalContent}>
          <div className={classes.closeButton}>
            <IconButton size='large' onClick={handleChangeModalState}>
              <CloseIcon sx={{ color: COLORS.gray, fontSize: '28px' }} />
            </IconButton>
          </div>
          <p className={fontClasses.h3}>{t('cruisesPage.newCruiseTitle')}</p>
          <div className={classes.textFieldBox}>
            <p className={clsx(fontClasses.p, fontClasses.semiBold)}>{t('cruisesPage.name')}</p>
            <TextField
              id='cruiseName'
              value={cruisName}
              onChange={(event) => {
                setCruiseName(event.target.value);
              }}
              variant='outlined'
              fullWidth
            />
          </div>
          {loading && (
            <div style={{ dis: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color='primary' />
            </div>
          )}
          <CustomPrimaryButton
            label={t('cruisesPage.addCruise')}
            fullWidth={isMobile}
            onClick={handleNewCruiseButton}
            disabled={loading || cruisName.length === 0}
          />
        </div>
      );
    };

    return (
      <div className={classes.section}>
        <div className={classes.mainTitle}>
          <h2 className={fontClasses.h3}>{t('cruisesPage.cruises')}</h2>
          {!isMobile && (
            <div className={classes.buttonDiv}>
              <CustomPrimaryButton
                label={t('cruisesPage.addCruise')}
                tertiary
                icon={<AddOutlinedIcon />}
                onClick={handleChangeModalState}
              />
            </div>
          )}
        </div>
        <div className={classes.resultList}>
          {cruisesList.length > 0 && !loadingData && !errorCruises && (
            <>
              {!isMobile && (
                <div className={classes.headerTableBox}>
                  <Grid container sx={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                      <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                        {t('cruisesPage.name')}
                      </p>
                    </Grid>
                  </Grid>
                </div>
              )}
              {cruisesList.map((item, index) => {
                return (
                  <CruiseCard
                    key={'cruise-item-' + index}
                    cruiseData={item}
                    deleteHandle={deleteCruise}
                    acordionExpanded={acordionExpanded}
                    setAcordionExpanded={setAcordionExpanded}
                    loadingDeleteCruise={loadingDeleteCruise}
                  />
                );
              })}
              {pageCount > 1 && isMobile && (
                <div className={classes.paginationStyle}>
                  <Pagination
                    count={pageCount}
                    color='primary'
                    page={pageNumber}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </>
          )}
          {errorCruises && (
            <div className={classes.emptyList}>
              <div className={classes.emptyMessageBox}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: '50px', color: COLORS.gray }} />
                <h2 className={fontClasses.h2}>{t('validationsForm.errorTitle')}</h2>
                <p className={fontClasses.p}>{t('validationsForm.errorMessage')}</p>
              </div>
            </div>
          )}
          {!loadingData && !errorCruises && cruisesList.length === 0 && (
            <div className={classes.emptyList}>
              <div className={classes.emptyMessageBox}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: '50px', color: COLORS.gray }} />
                <p className={fontClasses.p}>{t('cruisesPage.emptyMessage')}</p>
              </div>
            </div>
          )}
          {loadingData && (
            <div className={classes.loadingBox}>
              {[...Array(4)].map((__, index) => (
                <Skeleton
                  key={'skeleton-booking-' + index}
                  variant='rectangular'
                  width='100%'
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '50px',
                  }}
                />
              ))}
            </div>
          )}
        </div>
        {pageCount > 1 && !isMobile && (
          <div className={classes.paginationStyle}>
            <Pagination
              count={pageCount}
              color='primary'
              page={pageNumber}
              onChange={handlePageChange}
            />
          </div>
        )}
        <Modal
          pModalOpen={newCruiseModal}
          pHandleClose={handleChangeModalState}
          pModalContent={
            <ModalContent
              handleSuccesAddCruise={handleSuccesAddCruise}
              handleErrorAddCruise={handleErrorAddCruise}
              handleChangeModalState={handleChangeModalState}
            />
          }
        />
        {isMobile && (
          <Fab
            color='secondary'
            aria-label='add'
            sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
            onClick={handleChangeModalState}
          >
            <AddOutlinedIcon sx={{ color: COLORS.white }} />
          </Fab>
        )}
      </div>
    );
  };

  return (
    <div className={classes.mainWrapper}>
      <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={10}>
          <CruiseSection />
        </Grid>
      </Grid>
    </div>
  );
};

export default CruisePage;
