export function formatTour(response) {
  return response.tours.data.map((item) => ({
    id: item.id,
    name: item.attributes.name,
    overview: item.attributes.overview,
    price: item.attributes.price,
    minCapacity: item.attributes.min_capacity,
    location: item.attributes.location,
    languages: item.attributes.languages,
    itineray: item.attributes.itineray,
    isTicket: item.attributes.is_ticket,
    included: item.attributes.included,
    duration: item.attributes.duration,
    tag: item.attributes.inclutagded,
    images: item.attributes.images.data.map((item) => {
      return {
        url: item.attributes.url,
        alt: item.attributes.alternativeText,
      };
    }),
  }));
}
