import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PATHS } from '../../constants';
import styles from './styles';

const ToursDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = styles();
  const { booking } = location?.state || { booking: null };

  useEffect(() => {
    if (!booking) {
      navigate(PATHS.tours); // No tiene state entonces lo devuelve
    }
  }, [booking]);

  return (
    <div className={classes.mainWrapper}>
      <h1>Tour Details</h1>
      <p>{JSON.stringify(booking, null, 2)}</p>
    </div>
  );
};

export default ToursDetailsPage;
